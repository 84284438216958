import React from "react"
import AppLandingPage from "../components/app-landing-page"
import { Link } from "gatsby"
import ScreenshotIphone from "../components/ScreenshotIphone"
import ScreenshotMac from "../components/ScreenshotMac"
import ScreenshotTv from "../components/ScreenshotTv"
import {
  screenshotItem,
  desktopOnly,
  mobileOnly,
} from "../components/app-landing-page.module.css"
import Seo from "../components/seo"

const IntervalTimer = () => (
  <>
    <Seo
      title="Intervals Pro: Tabata & HIIT for iPhone, Mac, Apple TV"
      keywords={[
        "interval timer",
        "tabata timer",
        "workout timer",
        "exercise timer",
        "hiit timer",
        "run timer",
        "gym timer",
        "interval",
      ]}
      description="A simple, powerful, and customizable interval timer"
    />
    <AppLandingPage
      title="Intervals Pro: Tabata & HIIT for iPhone, Mac, Apple TV"
      screenshots={[
        <ScreenshotIphone
          className={screenshotItem}
          key="iphone-reset"
          type="reset"
        />,
        <ScreenshotMac
          className={`${screenshotItem} ${desktopOnly}`}
          key="mac"
        />,
        <ScreenshotTv
          className={`${screenshotItem} ${desktopOnly}`}
          key="tv"
        />,
        <ScreenshotIphone
          className={`${screenshotItem} ${mobileOnly}`}
          key="iphone-move"
          type="move"
        />,
        <ScreenshotIphone
          className={`${screenshotItem} ${mobileOnly}`}
          key="iphone-complete"
          type="complete"
        />,
      ]}
      subheadline={
        <span>
          Available on all your devices:{" "}
          <Link to="/on-iphone" key="iphone">
            iPhone
          </Link>
          ,{" "}
          <Link to="/on-ipad" key="ipad">
            iPad
          </Link>
          ,{" "}
          <Link to="/on-mac" key="mac">
            Mac
          </Link>
          , &{" "}
          <Link to="/on-apple-tv" key="tv">
            Apple TV
          </Link>
          .
        </span>
      }
      conversionLink="https://apps.apple.com/app/apple-store/id1516292869?pt=1963985&ct=u-1-blw-1&mt=8"
    />
  </>
)

export default IntervalTimer
