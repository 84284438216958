import React from "react"
import PropTypes from "prop-types"
import Image from "../images/hiit_it_interval_timer_tv-move.webp"
import Image2 from "../images/hiit_it_interval_timer_tv-move@2x.webp"
import Image3 from "../images/hiit_it_interval_timer_tv-move@3x.webp"
import Image4 from "../images/hiit_it_interval_timer_tv-move@4x.webp"

const ScreenshotTv = ({ className }) => (
  <div className={className}>
    <img
      srcSet={`${Image} 1x, ${Image2} 2x, ${Image3} 3x, ${Image4} 4x`}
      alt="Screenshot of Intervals Pro: Tabata & HIIT"
      height="200"
      width="340"
    />
  </div>
)

ScreenshotTv.propTypes = {
  className: PropTypes.string,
}

ScreenshotTv.defaultProps = {
  className: ``,
}

export default ScreenshotTv
