import React from "react"
import PropTypes from "prop-types"
import Image from "../images/hiit_it_interval_timer_mac-move.webp"
import Image2 from "../images/hiit_it_interval_timer_mac-move@2x.webp"
import Image3 from "../images/hiit_it_interval_timer_mac-move@3x.webp"
import Image4 from "../images/hiit_it_interval_timer_mac-move@4x.webp"

const ScreenshotMac = ({ className }) => (
  <div className={className}>
    <img
      srcSet={`${Image} 1x, ${Image2} 2x, ${Image3} 3x, ${Image4} 4x`}
      alt="Screenshot of Intervals Pro: Tabata & HIIT"
      height="200"
      width="344"
    />
  </div>
)

ScreenshotMac.propTypes = {
  className: PropTypes.string,
}

ScreenshotMac.defaultProps = {
  className: ``,
}

export default ScreenshotMac
